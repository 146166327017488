import { from } from "linq-to-typescript";
import { SpartaxFrage, SpartaxFragenfunktionKeys } from "./spartax-frage.dto";
import { SpartaxKunde, SpartaxKundeUtil } from "./spartax-kunde.dto";
import * as moment from 'moment';
import { SpartaxAuftrag, SpartaxAuftragUtil, SpartaxKonfigHonorare } from "./spartax-auftrag.dto";

//WARNING - Types MUST be kept in Sync between the Spartax Azure Function Project and with the ones in Beeexpert manually

/*
    NOTE:
        * A Property is editable only in beeexpert: It can be omitted in updateAuftragskundePropsFromAnother
        * A Property is editable in SharePoint and beeexpert: It must be updated in updateAuftragskundePropsFromAnother
*/
export type SpartaxAuftragskunde = {
    KundeFormModelId: string;
    Kunde: SpartaxKunde;
    AuftragsKundeArt: SpartaxAuftragskundeArt;
    Erklaerungsjahre?: SpartaxErklaerungsjahr[];
    ErklaerungsjahreAlt?: SpartaxErklaerungsjahr[];
    Unterbrechung?: boolean;
    Kommentare?: SpartaxFrageKommentar[];
    InternerKommentar?: SpartaxFrageInternerKommentar[];
    TeilDesAuftrags?: boolean;
    VerschlechterterGesunheitszustand?: boolean;
    FeststellungGradBehinderungNotwendig?: boolean;
    Pflegeaufwand?: boolean;
};

export abstract class SpartaxAuftragskundeUtil {
    public static createAuftragskundeFromQuestionnaire(kunde: SpartaxKunde, art: SpartaxAuftragskundeArt): SpartaxAuftragskunde {
        const result: SpartaxAuftragskunde = {
            KundeFormModelId: kunde.FormModelId,
            Kunde: kunde,
            AuftragsKundeArt: art,
        };
        result.Erklaerungsjahre = [];
        result.Unterbrechung = false;
        result.Erklaerungsjahre = SpartaxErklaerungsjahrUtil.initErklaerungsjahre(moment().year() - 6, moment().year() - 1);
        return result;
    }

    public static updateAuftragskundePropsFromAnother(sourceAuftragskunde: SpartaxAuftragskunde, targetAuftragskunde: SpartaxAuftragskunde): void {
        targetAuftragskunde.AuftragsKundeArt = sourceAuftragskunde.AuftragsKundeArt;
        for(let i = 0; i < targetAuftragskunde.Erklaerungsjahre.length; i++) {
            const erklaerungsjahrSource = from(sourceAuftragskunde.Erklaerungsjahre)
                .firstOrDefault(x => x.Jahr === targetAuftragskunde.Erklaerungsjahre[i].Jahr);
            if(erklaerungsjahrSource) {
                targetAuftragskunde.Erklaerungsjahre[i] = erklaerungsjahrSource;
            }
        }
        targetAuftragskunde.Unterbrechung = sourceAuftragskunde.Unterbrechung;
        targetAuftragskunde.Kommentare = sourceAuftragskunde.Kommentare;
        targetAuftragskunde.InternerKommentar = sourceAuftragskunde.InternerKommentar;
        targetAuftragskunde.TeilDesAuftrags = sourceAuftragskunde.TeilDesAuftrags;
    }
    
    public static getRueckzahlungen(auftragskunde: SpartaxAuftragskunde, ignoreNegativeValues: boolean) {
        return auftragskunde.TeilDesAuftrags ? 
            from(auftragskunde.Erklaerungsjahre)
                .where(x => ignoreNegativeValues ? SpartaxErklaerungsjahrUtil.getRueckzahlung(auftragskunde, x) > 0 : true)
                .sum(x => SpartaxErklaerungsjahrUtil.getRueckzahlung(auftragskunde, x)) : 0;
    }

    public static getSonderrabatte(auftragskunde: SpartaxAuftragskunde) {
        return auftragskunde.TeilDesAuftrags ? 
            from(auftragskunde.Erklaerungsjahre).sum(x => SpartaxErklaerungsjahrUtil.getSonderrabatt(auftragskunde, x)) : 0;
    }
    
    public static calculateHonorarBasis(auftragskunde: SpartaxAuftragskunde, fragen: SpartaxFrage[], auftrag: SpartaxAuftrag,
        honorarConfigs: SpartaxKonfigHonorare[]) {
        return from(auftragskunde.Erklaerungsjahre)
            .sum(x => SpartaxErklaerungsjahrUtil.calculateHonorarBasis(auftragskunde, x, fragen, auftrag, honorarConfigs));
    }

    public static calculateHonorarGesamt(auftragskunde: SpartaxAuftragskunde, fragen: SpartaxFrage[], auftrag: SpartaxAuftrag,
        honorarConfigs: SpartaxKonfigHonorare[]): number {
        return from(auftragskunde.Erklaerungsjahre)
            .sum(x => SpartaxErklaerungsjahrUtil.calculateHonorarGesamt(auftragskunde, x, fragen, auftrag, honorarConfigs));
    }

    public static calculateHonorarNichtVerrechnet(auftragskunde: SpartaxAuftragskunde, fragen: SpartaxFrage[], auftrag: SpartaxAuftrag,
        honorarConfigs: SpartaxKonfigHonorare[]): number {
        if(auftrag.SevDeskTotalInvoiceCreated && from(auftrag.Rechnungen || []).where(x => !x.Stornodatum).count() > 0){
            return 0;
        } else {
            if(from(auftrag.Rechnungen || []).where(x => !x.Stornodatum).any(x => x.KundeFormModelId == auftragskunde.KundeFormModelId)) {
                return 0;
            } else {
                return this.calculateHonorarGesamt(auftragskunde, fragen, auftrag, honorarConfigs);
            }
        }
    }

    public static getIncomeRelevantErklaerungsjahre(auftragskunde: SpartaxAuftragskunde) {
        let result: SpartaxErklaerungsjahr[] = [];
        if(auftragskunde) {
            if(auftragskunde.Kunde && auftragskunde.Kunde.VerstorbenAm) {
                result = from(auftragskunde.Erklaerungsjahre).where(x => x.Jahr <= moment(auftragskunde.Kunde.VerstorbenAm).year()).toArray();
            } else {
                result = auftragskunde.Erklaerungsjahre;
            }
        }
        return result;
    }

    public static getJahre(auftragskunde: SpartaxAuftragskunde) {
        if (auftragskunde.TeilDesAuftrags && auftragskunde.Erklaerungsjahre) {
            return from(auftragskunde.Erklaerungsjahre)
                    .where(x => SpartaxErklaerungsjahrUtil.hasYearIncome(x))
                    .select(x => x.Jahr).toArray();
        } else {
            return [];
        }
    }

    public static getJahreFormatted(auftragskunde: SpartaxAuftragskunde) {
        return this.getJahre(auftragskunde).join(', ');
    }
        
    public static getAllRelevantFragenOfAuftragskunde(auftragskunde: SpartaxAuftragskunde, fragen: SpartaxFrage[]) {
        const relevanteFragenIds: number[] = [];

        for(const erklaerungsjahr of auftragskunde.Erklaerungsjahre) {
            for(const frageAntwort of erklaerungsjahr.FragenAntworten) {
                const frage = fragen.find(x => x.ListItemId == frageAntwort.FrageListItemId);
                if(frage && 
                    frageAntwort.Antwort && 
                    relevanteFragenIds.indexOf(frage.ListItemId) === -1  &&
                    //Most of the time "Teil des Auftrags" defines visibility of a question
                    //Except questions marked with question function F124
                    (auftragskunde.TeilDesAuftrags || 
                        from(frage.Fragenfunktionen || []).any(x => x.Key === SpartaxFragenfunktionKeys.F124))
                ) {
                    relevanteFragenIds.push(frageAntwort.FrageListItemId);
                }
            }
        }

        return from(fragen)
            .where(x => relevanteFragenIds.includes(x.ListItemId))
            .orderBy(x => x.Fragenkategorie.Reihenfolge)
            .thenBy(x => x.Reihenfolge)
            .toArray();
    }

    public static stripAuftragskundeFromSensitiveData(auftragskunde: SpartaxAuftragskunde) {
        SpartaxKundeUtil.stripKundeOfSensitiveData(auftragskunde.Kunde);
        if(auftragskunde.Erklaerungsjahre) {
            for(const jahr of auftragskunde.Erklaerungsjahre) {
                SpartaxErklaerungsjahrUtil.stripErklaerungsjahrFromSensitiveData(jahr);
            }
        }
        delete auftragskunde.Unterbrechung;
        delete auftragskunde.Kommentare;
        delete auftragskunde.InternerKommentar;
    }
}

export enum SpartaxAuftragskundeArt {
    hauptkunde = 'hauptkunde',
    aktuellerPartner = 'aktuellerPartner',
    verstorbenerPartner = 'verstorbenerPartner',
    kind = 'kind'
}

export enum SpartaxSchaetzungsJahreseinkunftArt {
    wert1 = 'wert1',
    wert2 = 'wert2',
    wert3 = 'wert3',
    maximum = 'maximum'
}

export const SpartaxSchaetzungsJahreseinkunftArtOrder = [
    SpartaxSchaetzungsJahreseinkunftArt.wert1,
    SpartaxSchaetzungsJahreseinkunftArt.wert2,
    SpartaxSchaetzungsJahreseinkunftArt.wert3,
    SpartaxSchaetzungsJahreseinkunftArt.maximum
];

export type SpartaxErklaerungsjahr = {
    Jahr: number;
    EinkunftsArten?: SpartaxEinkunftsArt[];
    SchaetzungJahreseinkunft?: SpartaxSchaetzungsJahreseinkunftArt;
    UnterbrechungPraesenzdienst?: boolean;
    UnterbrechungKarenz?: boolean;
    IstGesellschafter?: boolean;
    FragenAntworten?: SpartaxFrageAntwort[];
    FragenBelegeErhalten?: SpartaxFrageBelegeErhalten[];
    Einreichdatum?: Date;
    Bescheiddatum?: Date;
    Rueckzahlung?: number;
    Sonderrabatt?: number;
    Wiederaufnahme?: boolean;
    Pflichtveranlagung?: boolean;
    EinnahmenAusgabenRechnungGewerbe?: boolean;
    EinnahmenAusgabenRechnungVermietung?: boolean;
};

export abstract class SpartaxErklaerungsjahrUtil {
    public static initErklaerungsjahre(firstYear: number, lastYear: number): SpartaxErklaerungsjahr[] {
        const result: SpartaxErklaerungsjahr[] = [];
        for (let year = firstYear; year <= lastYear; year++) {
            const erklaerungsjahr: SpartaxErklaerungsjahr = {
                Jahr: year,
            };
            erklaerungsjahr.EinkunftsArten = [];
            erklaerungsjahr.FragenAntworten = [];
            result.push(erklaerungsjahr);
        }
        return result;
    }

    public static hasYearIncome(erklaerungsjahr: SpartaxErklaerungsjahr) {
        return erklaerungsjahr.EinkunftsArten && erklaerungsjahr.EinkunftsArten.length > 0;
    }
        
    public static isPflichtveranlagung(erklaerungsjahr: SpartaxErklaerungsjahr, fragen: SpartaxFrage[]) {
        if(erklaerungsjahr && this.hasYearIncome(erklaerungsjahr)) {
            const valueWasOverriden = erklaerungsjahr.Pflichtveranlagung !== undefined && erklaerungsjahr.Pflichtveranlagung !== null;
            if(valueWasOverriden) {
                return erklaerungsjahr.Pflichtveranlagung;
            } else {
                const fragenWithPflichtveranlagung = from(fragen).where(x => !!x.Pflichtveranlagung).select(x => x.ListItemId).toArray();
                return from(erklaerungsjahr.FragenAntworten).where(x => !!x.Antwort)
                    .select(x => x.FrageListItemId).any(x => fragenWithPflichtveranlagung.includes(x));
            }
        } else {
            return false;
        }
    }

    public static isEinnahmenAusgabenRechnungGewerbe(erklaerungsjahr: SpartaxErklaerungsjahr) {
        if(erklaerungsjahr && this.hasYearIncome(erklaerungsjahr)) {
            const valueWasOverriden = erklaerungsjahr.EinnahmenAusgabenRechnungGewerbe !== undefined && erklaerungsjahr.EinnahmenAusgabenRechnungGewerbe !== null;
            if(valueWasOverriden) {
                return erklaerungsjahr.EinnahmenAusgabenRechnungGewerbe;
            } else {
                return erklaerungsjahr.EinkunftsArten.includes(SpartaxEinkunftsArt.betrieb);
            }
        } else {
            return false;
        }        
    }

    public static isEinnahmenAusgabenRechnungVermietung(erklaerungsjahr: SpartaxErklaerungsjahr) {
        if(erklaerungsjahr && this.hasYearIncome(erklaerungsjahr)) {
            const valueWasOverriden = erklaerungsjahr.EinnahmenAusgabenRechnungVermietung !== undefined && erklaerungsjahr.EinnahmenAusgabenRechnungVermietung !== null;
            if(valueWasOverriden) {
                return erklaerungsjahr.EinnahmenAusgabenRechnungVermietung;
            } else {
                return erklaerungsjahr.EinkunftsArten.includes(SpartaxEinkunftsArt.vermietung);
            }
        } else {
            return false;
        }
    }

    public static getRueckzahlung(auftragskunde: SpartaxAuftragskunde, erklaerungsjahr: SpartaxErklaerungsjahr) {
        return auftragskunde.TeilDesAuftrags && erklaerungsjahr && this.hasYearIncome(erklaerungsjahr) ? 
            erklaerungsjahr.Rueckzahlung || 0 : 0;
    }

    public static getSonderrabatt(auftragskunde: SpartaxAuftragskunde, erklaerungsjahr: SpartaxErklaerungsjahr) {
        return auftragskunde.TeilDesAuftrags && erklaerungsjahr && this.hasYearIncome(erklaerungsjahr) ? 
            erklaerungsjahr.Sonderrabatt || 0 : 0;
    }

    public static getHonorarWiederaufnahme(auftragskunde: SpartaxAuftragskunde, erklaerungsjahr: SpartaxErklaerungsjahr, 
        auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
            return auftragskunde.TeilDesAuftrags && erklaerungsjahr && this.hasYearIncome(erklaerungsjahr) 
                && erklaerungsjahr.Wiederaufnahme ? 
                    SpartaxAuftragUtil.getHonorarBasisWiederaufnahme(auftrag, honorarConfigs) : 0;
    }

    public static getHonorarPflichtveranlagung(auftragskunde: SpartaxAuftragskunde, erklaerungsjahr: SpartaxErklaerungsjahr, 
        fragen: SpartaxFrage[], auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        return auftragskunde.TeilDesAuftrags && this.isPflichtveranlagung(erklaerungsjahr, fragen) ? 
            SpartaxAuftragUtil.getHonorarBasisPflichtveranlagung(auftrag, honorarConfigs) : 0;
    }

    public static getHonorarEinnahmenAusgabenRechnungGewerbe(auftragskunde: SpartaxAuftragskunde, 
        erklaerungsjahr: SpartaxErklaerungsjahr, auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        return auftragskunde.TeilDesAuftrags && this.isEinnahmenAusgabenRechnungGewerbe(erklaerungsjahr) ? 
            SpartaxAuftragUtil.getHonorarBasisEinnahmenAusgabenRechnungGewerbe(auftrag, honorarConfigs) : 0;
    }

    public static getHonorarEinnahmenAusgabenRechnungVermietung(auftragskunde: SpartaxAuftragskunde, 
        erklaerungsjahr: SpartaxErklaerungsjahr, auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        return auftragskunde.TeilDesAuftrags && this.isEinnahmenAusgabenRechnungVermietung(erklaerungsjahr) ? 
            SpartaxAuftragUtil.getHonorarBasisEinnahmenAusgabenRechnungVermietung(auftrag, honorarConfigs) : 0;
    }

    public static calculateRueckzahlungHonorar(auftragskunde: SpartaxAuftragskunde, erklaerungsjahr: SpartaxErklaerungsjahr, auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
        return SpartaxErklaerungsjahrUtil.getRueckzahlung(auftragskunde, erklaerungsjahr) * 
            SpartaxAuftragUtil.calculateMischSatz(auftrag, honorarConfigs) / 1.2;
    }

    public static calculateMindesthonorarHonorar(auftragskunde: SpartaxAuftragskunde, erklaerungsjahr: SpartaxErklaerungsjahr, fragen: SpartaxFrage[], auftrag: SpartaxAuftrag,
        honorarConfigs: SpartaxKonfigHonorare[]) {
            return this.getHonorarWiederaufnahme(auftragskunde, erklaerungsjahr, auftrag, honorarConfigs) + 
            this.getHonorarPflichtveranlagung(auftragskunde, erklaerungsjahr, fragen, auftrag, honorarConfigs) + 
            this.getHonorarEinnahmenAusgabenRechnungGewerbe(auftragskunde, erklaerungsjahr, auftrag, honorarConfigs) + 
            this.getHonorarEinnahmenAusgabenRechnungVermietung(auftragskunde, erklaerungsjahr, auftrag, honorarConfigs);
    }

    public static isRueckzahlungGreaterThanMindesthonorar(auftragskunde: SpartaxAuftragskunde, 
        erklaerungsjahr: SpartaxErklaerungsjahr, fragen: SpartaxFrage[], auftrag: SpartaxAuftrag,
        honorarConfigs: SpartaxKonfigHonorare[]) {
        return this.calculateRueckzahlungHonorar(auftragskunde, erklaerungsjahr, auftrag, honorarConfigs) >
            this.calculateMindesthonorarHonorar(auftragskunde, erklaerungsjahr, fragen, auftrag, honorarConfigs);
    }
    
    public static calculateHonorarBasis(auftragskunde: SpartaxAuftragskunde, erklaerungsjahr: SpartaxErklaerungsjahr, 
        fragen: SpartaxFrage[], auftrag: SpartaxAuftrag, honorarConfigs: SpartaxKonfigHonorare[]) {
            return this.isRueckzahlungGreaterThanMindesthonorar(auftragskunde, erklaerungsjahr, fragen, auftrag, honorarConfigs) ?
                    this.calculateRueckzahlungHonorar(auftragskunde, erklaerungsjahr, auftrag, honorarConfigs) :
                    this.calculateMindesthonorarHonorar(auftragskunde, erklaerungsjahr, fragen, auftrag, honorarConfigs);
    }

    public static calculateHonorarGesamt(auftragskunde: SpartaxAuftragskunde, erklaerungsjahr: SpartaxErklaerungsjahr, fragen: SpartaxFrage[], auftrag: SpartaxAuftrag,
        honorarConfigs: SpartaxKonfigHonorare[]) {
        return this.calculateHonorarBasis(auftragskunde, erklaerungsjahr, fragen, auftrag, honorarConfigs) - 
            this.getSonderrabatt(auftragskunde, erklaerungsjahr);
    }

    public static stripErklaerungsjahrFromSensitiveData(erklaerungsjahr: SpartaxErklaerungsjahr) {
        delete erklaerungsjahr.EinkunftsArten;
        delete erklaerungsjahr.SchaetzungJahreseinkunft;
        delete erklaerungsjahr.UnterbrechungPraesenzdienst;
        delete erklaerungsjahr.UnterbrechungKarenz;
        delete erklaerungsjahr.IstGesellschafter;
        delete erklaerungsjahr.Einreichdatum;
        delete erklaerungsjahr.Bescheiddatum;
        delete erklaerungsjahr.Rueckzahlung;
        delete erklaerungsjahr.Sonderrabatt;
        delete erklaerungsjahr.Wiederaufnahme;
        delete erklaerungsjahr.Pflichtveranlagung;
        delete erklaerungsjahr.EinnahmenAusgabenRechnungGewerbe;
        delete erklaerungsjahr.EinnahmenAusgabenRechnungVermietung;
    }

    public static stripErklaerungsjahrIfNotTeilDesAuftrags(erklaerungsjahr: SpartaxErklaerungsjahr) {
        delete erklaerungsjahr.Einreichdatum;
        delete erklaerungsjahr.Bescheiddatum;
        delete erklaerungsjahr.Rueckzahlung;
        delete erklaerungsjahr.Sonderrabatt;
        delete erklaerungsjahr.Wiederaufnahme;
        delete erklaerungsjahr.Pflichtveranlagung;
        delete erklaerungsjahr.EinnahmenAusgabenRechnungGewerbe;
        delete erklaerungsjahr.EinnahmenAusgabenRechnungVermietung;
    }
}

export enum SpartaxEinkunftsArt {
    dienstverhaeltnis = 'dienstverhaeltnis',
    betrieb = 'betrieb',
    vermietung = 'vermietung',
    kapitalvermoegen = 'kapitalvermoegen',
    pension = 'pension',
}

export const SpartaxEinkunftsArtenName = new Map<string, string>([
    [SpartaxEinkunftsArt.dienstverhaeltnis, 'Dienstverhältnis'],
    [SpartaxEinkunftsArt.betrieb, 'Betrieb'],
    [SpartaxEinkunftsArt.vermietung, 'Vermietung / Verpachtung'],
    [SpartaxEinkunftsArt.kapitalvermoegen, 'Kapitalvermögen'],
    [SpartaxEinkunftsArt.pension, 'Pension'],
]);

export const SpartaxEinkunftsArtenDescription = new Map<string, string>([
    [SpartaxEinkunftsArt.dienstverhaeltnis, 'Beschäftigung in Voll- / Teilzeit oder geringfügig inkl. steuerpflichtige Entschädigungen von Krankenkasse, BUAK, Bundesheer, …'],
    [SpartaxEinkunftsArt.betrieb, 'Gewerbebetrieb, selbständige Arbeit, nichtpauschalierte Land- / Forstwirtschaft'],
    [SpartaxEinkunftsArt.vermietung, ''],
    [SpartaxEinkunftsArt.kapitalvermoegen, 'Nur, wenn keine KESt abgeführt wurde'],
    [SpartaxEinkunftsArt.pension, 'Auch Invaliditäts- / Berufsunfähigkeitspension, sowie Witwen- / Waisenpension'],
]);

export type SpartaxFrageAntwort = {
    FrageListItemId: number;
    Antwort?: boolean;
};

export type SpartaxFrageBelegeErhalten = {
    FrageListItemId: number;
    BelegeErhaltenAm?: Date;
};

export type SpartaxFrageKommentar = {
    FrageListItemId: number;
    Kommentar: string;
};

export type SpartaxFrageInternerKommentar = {
    FrageListItemId: number;
    InternerKommentar: string;
};
