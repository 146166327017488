import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import * as IBAN from 'iban';
import { from } from 'linq-to-typescript';
import * as moment from 'moment';
import { SpartaxAuftragskunde, SpartaxAuftragskundeArt } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxKind, SpartaxKunde, SpartaxKundeUtil } from 'src/app/data/dto/spartax/spartax-kunde.dto';
import { SpartaxService } from 'src/app/data/service/spartax.service';

@Component({
  selector: 'app-spartax-identity',
  templateUrl: './spartax-identity.component.html',
  styleUrls: ['./spartax-identity.component.scss'],
})
export class SpartaxIdentityComponent {
  @Input() 
  kunde: SpartaxKunde;

  @Input()
  isHauptkunde: boolean;

  @Input()
  edit: boolean;

  @Input()
  debug: boolean;

  @Input()
  teilDesAuftrages: boolean;

  @Input()
  spartaxPortalKunden: Map<string, SpartaxKunde>;

  @Input()
  auftragskunden: SpartaxAuftragskunde[];

  @Input()
  kinder: SpartaxKind[];
  
  @Output()
  kundeChange = new EventEmitter<SpartaxKunde>();

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Output()
  spartaxPortalKundenChange = new EventEmitter<Map<string, SpartaxKunde>>();
  
  @Output()
  auftragskundenChange = new EventEmitter<SpartaxAuftragskunde[]>();

  @Output()
  kinderChange = new EventEmitter<SpartaxKind[]>();

  @ViewChild('formElement')
  ngForm!: NgForm;

  public errorMsg = "";
  public possibleKundenMatches: {key: string, value: string}[] = [];
  public queryingKunden: boolean = false;
  public loadingKunde: boolean = false;
  public existingKundeLinked: boolean = false;

  private kundeBackup: SpartaxKunde;

  constructor(
    private spartaxService: SpartaxService,
    private alertController: AlertController
  ) { }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  formIsValid() {
    this.errorMsg = "";
    if (this.edit) {
      if (!this.ngForm.valid) {
        return false;
      }
      if (this.ngForm.form.value.PLZWrite) {
        if ((this.ngForm.form.value.PLZWrite + "").length != 4) {
          this.errorMsg = "Die Postleitzahl muss 4-stellig sein."
          return false;
        }
      }
    }
    return true;
  }

  get queryKundenTitle() {
    return this.canQueryKunden ? "In Bestandskunden suchen..." : "Erfassen Sie weitere Daten, um nach Bestandskunden zu suchen...";
  }

  get canQueryKunden() {  
    return !this.queryingKunden && this.kunde.Vorname && this.kunde.Vorname.length >= 3 &&
      this.kunde.Nachname && this.kunde.Nachname.length >= 3 &&
      (this.kunde.PLZ && this.kunde.PLZ.toString().length >= 4);
  }

  onVornameChange(value: string) {
    this.kunde.Vorname = value;
    
    //Because we match the current partners name with the new name, we need to update the partner name as well
    const auftragsKunde = this.auftragskunden.find(x => x.KundeFormModelId === this.kunde.FormModelId);
    if(auftragsKunde && auftragsKunde.AuftragsKundeArt == SpartaxAuftragskundeArt.aktuellerPartner) {
      const hauptkunde = this.auftragskunden.find(x => x.AuftragsKundeArt == SpartaxAuftragskundeArt.hauptkunde);
      hauptkunde.Kunde.Familienstaende[0].VornamePartner = this.kunde.Vorname;

      if(this.kinder) {
        for(const kind of this.kinder) {
          if(this.kunde.Geschlecht == 'Weiblich' && !kind.MutterIstExPartner) {
            kind.Mutter = this.kunde.Vorname;
          }
  
          if(this.kunde.Geschlecht == 'Männlich' && !kind.VaterIstExPartner) {
            kind.Vater = this.kunde.Vorname;
          }
        }
      }
    }
  }

  async queryKunden() {
    if(!this.kunde.ListItemId) {
      if(this.canQueryKunden) {
        try {
          this.queryingKunden = true;
          let query = "";
  
          query += (this.kunde.TitelVorgestellt ? this.kunde.TitelVorgestellt + " " : "")  
            + (this.kunde.Vorname ? this.kunde.Vorname + " " : "")
            + (this.kunde.Nachname ? this.kunde.Nachname : "")
            + (this.kunde.TitelNachgestellt ? " " + this.kunde.TitelNachgestellt : "") 
            + (this.kunde.PLZ ? " " + this.kunde.PLZ : "");
  
          this.possibleKundenMatches = await this.spartaxService.queryCustomers(query);
        } catch(error) {
          console.error(error);
        }
        
        this.queryingKunden = false;
      }
    }
  }
    
  async onExistingCustomerSelected(key: string) {
    /*
      NOTE -> Currently, this method is duplicated in the spartax-identity and spartax-place-order components,
      because a change to the functionality is already planned and will be implemented in the future.
    */
    if(from(this.auftragskunden).any(x => x.Kunde.ListItemId === +key)) {
      const alert = await this.alertController.create({
        subHeader: 'Der ausgewählte Kunde ist bereits einem anderen Auftragskunden zugeordnet.',
        buttons: ['OK']
      });
      await alert.present();
    } else {
      this.loadingKunde = true;
      this.kundeBackup = {...this.kunde};
      const kundeFromSharePoint = await this.spartaxService.getCustomerById(+key);
      SpartaxKundeUtil.updateKundePropsFromAnother(kundeFromSharePoint, this.kunde);
      this.kunde.procedureDiscussed = true;
      this.kunde.Partner = this.kundeBackup.Partner;
      this.kunde.FormModelIdPartner = this.kundeBackup.FormModelIdPartner;
      this.kunde.ListItemIdPartner = this.kundeBackup.ListItemIdPartner;
      this.kunde.Familienstaende = this.kundeBackup.Familienstaende;
      this.kunde.GemeinsamerHaushalt = this.kundeBackup.GemeinsamerHaushalt;
      this.kundeChange.emit(this.kunde);
  
      //Swap all references to the old formModelId in auftragskunden
      for(const auftragskunde of this.auftragskunden) {
        if(auftragskunde.KundeFormModelId === this.kundeBackup.FormModelId) {
          auftragskunde.KundeFormModelId = kundeFromSharePoint.FormModelId;
        }
  
        if(auftragskunde.Kunde.FormModelIdPartner === this.kundeBackup.FormModelId) {
          auftragskunde.Kunde.FormModelIdPartner = kundeFromSharePoint.FormModelId;
        }
  
        if(auftragskunde.Kunde.ListItemIdPartner === this.kundeBackup.ListItemId) {
          auftragskunde.Kunde.ListItemIdPartner = kundeFromSharePoint.ListItemId;
        }
  
        if(auftragskunde.Kunde.FormModelIdMutter === this.kundeBackup.FormModelId) {
          auftragskunde.Kunde.FormModelIdMutter = kundeFromSharePoint.FormModelId;
        }
        
        if(auftragskunde.Kunde.FormModelIdVater === this.kundeBackup.FormModelId) {
          auftragskunde.Kunde.FormModelIdVater = kundeFromSharePoint.FormModelId;
        }
  
        if(auftragskunde.Kunde.FormModelIdsKinder) {
          for(let i = 0; i < auftragskunde.Kunde.FormModelIdsKinder.length; i++) {
            if(auftragskunde.Kunde.FormModelIdsKinder[i] === this.kundeBackup.FormModelId) {
              auftragskunde.Kunde.FormModelIdsKinder[i] = kundeFromSharePoint.FormModelId;
            }
          }
        }
  
        if(auftragskunde.Kunde.ListItemIdsKinder) {
          for(let i = 0; i < auftragskunde.Kunde.ListItemIdsKinder.length; i++) {
            if(auftragskunde.Kunde.ListItemIdsKinder[i] === this.kundeBackup.ListItemId) {
              auftragskunde.Kunde.ListItemIdsKinder[i] = kundeFromSharePoint.ListItemId;
            }
          }
        }
      }
          
      this.auftragskundenChange.emit(this.auftragskunden);
  
      for(const kind of this.kinder) {
        if(kind.KundeFormModelId === this.kundeBackup.FormModelId) {
          kind.KundeFormModelId = kundeFromSharePoint.FormModelId;
        }
      }
      this.kinderChange.emit(this.kinder);
  
      this.spartaxPortalKunden.set(this.kunde.FormModelId, this.kunde);
      this.spartaxPortalKundenChange.emit(this.spartaxPortalKunden);
      
      this.loadingKunde = false;
      this.existingKundeLinked = true;
    }
  }

  onRemoveExistingCustomerLink() {
    const oldFormModelId = this.kunde.FormModelId;
    this.kunde = {...this.kundeBackup};
    this.kunde.procedureDiscussed = true;
    this.kundeChange.emit(this.kunde);
    from(this.auftragskunden).first(x => x.KundeFormModelId === oldFormModelId).KundeFormModelId = this.kunde.FormModelId;
    this.auftragskundenChange.emit(this.auftragskunden);
    this.spartaxPortalKunden.delete(this.kunde.FormModelId);
    this.spartaxPortalKundenChange.emit(this.spartaxPortalKunden);
    this.existingKundeLinked = false;
  }
}
