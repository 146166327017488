import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IonToggle } from '@ionic/angular';
import { SpartaxAuftragskunde } from 'src/app/data/dto/spartax/spartax-auftragskunde.dto';
import { SpartaxFormModelDto, SpartaxFormModelDtoUtil } from 'src/app/data/dto/spartax/spartax-form-model.dto';
import { SpartaxKind } from 'src/app/data/dto/spartax/spartax-kunde.dto';

@Component({
  selector: 'app-spartax-children',
  templateUrl: './spartax-children.component.html',
  styleUrls: ['./spartax-children.component.scss'],
})
export class SpartaxChildrenComponent implements OnInit {
  @Input() 
  model: SpartaxFormModelDto;

  @Output()
  modelChange = new EventEmitter<SpartaxFormModelDto>();

  @Input()
  edit: boolean;

  @ViewChild('formElement')
  ngForm!: NgForm;

  public working: boolean = true;
  public checkboxStateOverride = false;  

  public get hauptkunde(): SpartaxAuftragskunde {
    return SpartaxFormModelDtoUtil.getHauptkundeAuftragskunde(this.model);
  }

  public get aktuellerPartner(): SpartaxAuftragskunde {
    return SpartaxFormModelDtoUtil.getPartnerAuftragskunde(this.model);
  }
  
  constructor() { }

  ngOnInit() { }

  formIsValid() {
    return this.ngForm.valid;
  }

  addChild() {
    const newKind: SpartaxKind = {};
    this.onFatherChange(newKind, this.possibleFathers.keys().next().value);
    this.onMotherChange(newKind, this.possibleMothers.keys().next().value);
    this.model.kinder.push(newKind);
  }

  ngAfterViewInit() {
    this.working = false;
  }
  
  get isHauptkundeMale() {
    return this.hauptkunde.Kunde.Geschlecht === 'Männlich';
  }

  get isHauptkundeFemale() {
    return this.hauptkunde.Kunde.Geschlecht === 'Weiblich';
  }

  get primaryFather() {
    if (this.isHauptkundeMale) {
      return this.hauptkunde;
    } else {
      return this.aktuellerPartner;
    }
  }

  get primaryMother() {
    if (this.isHauptkundeFemale) {
      return this.hauptkunde;
    } else {
      return this.aktuellerPartner;
    }
  }

  get possibleFathers(){
    const result = new Map<number, string>();
    if (this.primaryFather) {
      result.set(0, this.primaryFather.Kunde.Vorname);
    }
    if (this.primaryMother) {
      result.set(1, `Ex-Partner von ${this.primaryMother.Kunde.Vorname}`);
    }
    result.set(2, "Pflegekind");
    return result;
  }
  
  getFatherValue(kind: SpartaxKind) {
    if(kind.VaterIstExPartner) {
      return 1;
    } else if (kind.VaterIstPflegevater) {
      return 2;
    } else {
      return 0;
    }
  }

  onFatherChange(kind: SpartaxKind, value: number, updateMother = true) {
    kind.Vater = this.possibleFathers.get(value);

    if(value === 0) {
      kind.VaterFormModelId = this.primaryFather.Kunde.FormModelId;
      kind.VaterIstExPartner = false;
      kind.VaterIstPflegevater = false;
    } else if (value === 1) {
      kind.VaterFormModelId = null;
      kind.VaterIstExPartner = true;
      kind.VaterIstPflegevater = false;
    } else if (value === 2) {
      kind.VaterFormModelId = null;
      kind.VaterIstPflegevater = true;
      kind.VaterIstExPartner = false;
      if (updateMother) {
        this.onMotherChange(kind, 2, false);
      }
    }
    this.onFamilienbeihilfeChange(kind, kind.FamilienBeihilfe);
  }

  get possibleMothers() {
    const result = new Map<number, string>();
    if(this.primaryMother) {
      result.set(0, this.primaryMother.Kunde.Vorname);
    }
    if(this.primaryFather) {
      result.set(1, `Ex-Partnerin von ${this.primaryFather.Kunde.Vorname}`);
    }
    result.set(2, "Pflegekind");
    return result;
  }

  getMotherValue(kind: SpartaxKind) {
    if(kind.MutterIstExPartner) {
      return 1;
    } else if (kind.MutterIstPflegemutter) {
      return 2;
    } else {
      return 0;
    }
  }

  onMotherChange(kind: SpartaxKind, value: number, updateFather = true) {
    kind.Mutter = this.possibleMothers.get(value);

    if(value === 0) {
      kind.MutterFormModelId = this.primaryMother.Kunde.FormModelId;
      kind.MutterIstExPartner = false;
      kind.MutterIstPflegemutter = false;
    } else if (value === 1) {
      kind.MutterFormModelId = null;
      kind.MutterIstExPartner = true;
      kind.MutterIstPflegemutter = false;
    } else if (value === 2) {
      kind.MutterFormModelId = null;
      kind.MutterIstPflegemutter = true;
      kind.MutterIstExPartner = false;
      if (updateFather) {
        this.onFatherChange(kind, 2, false);
      }
    }
    this.onFamilienbeihilfeChange(kind, kind.FamilienBeihilfe);
  }

  removeItem(array, idx) {
    this.working = true;
    let newarray = [];
    let currentIdx = 0;
    for (let val of array) {
      if (currentIdx != idx) {
        newarray.push(val);
      }
      currentIdx++;
    }
    this.model.kinder = newarray;
    setTimeout(() => {
      this.working = false;
    }, 50);
  }

  markAllControlsAsTouched() {
    for (const control in this.ngForm.controls) {
      this.ngForm.controls[control].markAsTouched();
    }
  }

  onFamilienbeihilfeChange(kind: SpartaxKind, value: boolean) {
    this.checkboxStateOverride = true;
    kind.FamilienBeihilfe = value;
    if (kind.FamilienBeihilfe) {
      if (kind.MutterIstExPartner) {
        kind.FamilienBeihilfeErhaltenFormModelId = this.primaryFather.Kunde.FormModelId;
      } else if (kind.VaterIstExPartner) {
        kind.FamilienBeihilfeErhaltenFormModelId = this.primaryMother.Kunde.FormModelId;
      } else {
        if(this.primaryMother && this.primaryMother.Kunde) {
          kind.FamilienBeihilfeErhaltenFormModelId = this.primaryMother.Kunde.FormModelId;
        } else {
          kind.FamilienBeihilfeErhaltenFormModelId = this.primaryFather.Kunde.FormModelId;
        }
      }
    } else {
      kind.FamilienBeihilfeErhaltenFormModelId = null;
      kind.ErhoehteFamilienBeihilfe = false;
      kind.AlimenteErhalten = false;
    }
    setTimeout(() => {
      this.checkboxStateOverride = false;
    }, 0);
  }

  getFamilienbeihilfeErhaltenValue(kind: SpartaxKind) {
    return kind.FamilienBeihilfeErhaltenFormModelId === this.primaryFather.Kunde?.FormModelId ? 0 : 1;
  }

  onFamilienBeihilfeIchErhaltenChange(kind: SpartaxKind, event: Event) {
    const value = (event.target as unknown as IonToggle).checked;
    if (value) {
      kind.FamilienBeihilfeErhaltenFormModelId = this.primaryMother.Kunde.FormModelId;
    } else {
      kind.FamilienBeihilfeErhaltenFormModelId = this.primaryFather.Kunde.FormModelId;
    }
  }

  onAlimenteBezahltChange(kind: SpartaxKind, value: boolean) {
    kind.AlimenteBezahlt = value;
    if (kind.AlimenteBezahlt) {
      if (kind.MutterIstExPartner || kind.VaterIstExPartner) {
        kind.AlimenteIchBezahlt = true;
      }
    } else {
      kind.AlimenteIchBezahlt = false;
    }
  }

  onAlimenteErhaltenChange(kind: SpartaxKind, value: boolean) {
    kind.AlimenteErhalten = value;
  }
}
